import React from "react";
import * as Icon from "react-feather";
import reminder from "../assets/icons/svg/reminder.svg";
import { accountIds } from "../utility/store/accountIds";
import { permissionIdList } from "./permissionIdList";

const isProd = process.env.REACT_APP_STAGE === "build for prod";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: [
      "super",
      "admin",
      "area_manager",
      "designer",
      "admin_read_only",
      "order_read_only",
    ],
    navLink: "/dashboard",
    activeLink: "/dashboard",
    permissionId: [permissionIdList.DASHBOARD],
  },
  {
    id: "designer-profile",
    title: "My Profile",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["designer"],
    navLink: "/designer/profile",
    activeLink: "/designer/profile",
    permissionId: [permissionIdList.DESIGNER.PROFILE],
  },
  {
    id: "designer-order",
    title: "My Orders",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["designer"],
    navLink: "/designer/order?page=1&size=10",
    activeLink: "/designer/order",
    permissionId: [permissionIdList.DESIGNER.ORDER],
  },
  {
    id: "designer-earn",
    title: "My Earnings",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["designer"],
    navLink: "/designer/earning?page=0&size=10&status=completed",
    activeLink: "/designer/earning",
    permissionId: [permissionIdList.DESIGNER.EARNING],
  },
  // {
  //   id: "designer-draft",
  //   title: "Draft",
  //   type: "item",
  //   icon: <Icon.Package size={20} />,
  //   permissions: ["designer"],
  //   navLink: "/designer/draft",
  //   activeLink: "/designer/draft"
  // },
  {
    type: "groupHeader",
    groupTitle: "Manage Users",
    permissions: ["super", "admin", "area_manager"],
    permissionId: [
      permissionIdList.MANAGE_USERS.ALL_USERS.LIST,
      permissionIdList.MANAGE_USERS.ADMIN.LIST,
      permissionIdList.MANAGE_USERS.AREA_MANAGER.LIST,
      permissionIdList.MANAGE_USERS.DESIGNER.LIST,
      permissionIdList.MANAGE_USERS.FITTER.LIST,
      permissionIdList.MANAGE_USERS.DELIVERY_AGENT.LIST,
      permissionIdList.MANAGE_USERS.OTHER_USERS.LIST,
      permissionIdList.MANAGE_USERS.CUSTOMER_CARE.LIST,
      permissionIdList.MANAGE_USERS.CMS_PERSON.LIST,
    ],
  },
  {
    id: "all-users",
    title: "All Users",
    type: "item",
    icon: <Icon.List size={24} />,
    navLink: "/manage_users/all-users?page=1&size=10",
    activeLink: "/manage_users/all-users",
    permissionId: [permissionIdList.MANAGE_USERS.ALL_USERS.LIST],
  },
  {
    id: "admin",
    title: "Admins",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super", "editor"],
    navLink: "/manage_users/admin?page=1&size=10",
    activeLink: "/manage_users/admin",
    permissionId: [permissionIdList.MANAGE_USERS.ADMIN.LIST],
  },
  {
    id: "areamgrs",
    title: "Coaches",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super", "admin", "editor"],
    navLink: "/manage_users/area-manager?page=1&size=10",
    activeLink: "/manage_users/area-manager",
    permissionId: [permissionIdList.MANAGE_USERS.AREA_MANAGER.LIST],
  },
  {
    id: "designers",
    title: "Designers",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super", "admin", "area_manager"],
    navLink: "/manage_users/designer?page=1&size=10",
    activeLink: "/manage_users/designer",
    permissionId: [permissionIdList.MANAGE_USERS.DESIGNER.LIST],
  },
  {
    id: "fitters",
    title: "Fitters",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super", "admin", "area_manager"],
    navLink: "/manage_users/fitter?page=1&size=10",
    activeLink: "/manage_users/fitter",
    permissionId: [permissionIdList.MANAGE_USERS.FITTER.LIST],
  },
  {
    id: "deliverers",
    title: "Delivery Agents",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super", "admin", "area_manager"],
    navLink: "/manage_users/delivery-agent?page=1&size=10",
    activeLink: "/manage_users/delivery-agent",
    permissionId: [permissionIdList.MANAGE_USERS.DELIVERY_AGENT.LIST],
  },
  {
    id: "otherUsers",
    title: "Other Users",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super"],
    navLink: "/manage_users/other-users?page=1&size=10",
    activeLink: "/manage_users/other-users",
    permissionId: [permissionIdList.MANAGE_USERS.OTHER_USERS.LIST],
  },
  {
    id: "customercare",
    title: "Customer Care",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super"],
    navLink: "/manage_users/customer_care?page=1&size=10",
    activeLink: "/manage_users/customer_care",
    permissionId: [permissionIdList.MANAGE_USERS.CUSTOMER_CARE.LIST],
  },
  {
    id: "cmsperson",
    title: "CMS Persons",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super"],
    navLink: "/manage_users/cms_person?page=1&size=10",
    activeLink: "/manage_users/cms_person",
    permissionId: [permissionIdList.MANAGE_USERS.CMS_PERSON.LIST],
  },
  {
    id: "branches",
    title: "Branches",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super", "admin"],
    navLink: "/manage_users/branches?page=1&size=10",
    activeLink: "/manage_users/branches",
    permissionId: [permissionIdList.MANAGE_USERS.BRANCHES.LIST],
  },
  {
    id: "brands",
    title: "Manufacturer",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super"],
    navLink: "/manage_users/brands?page=1&size=10",
    activeLink: "/manage_users/brands",
    permissionId: [permissionIdList.MANAGE_USERS.BRANDS.LIST],
  },
  {
    type: "groupHeader",
    groupTitle: "Manage Orders",
    permissions: [
      "super",
      "admin",
      "area_manager",
      "customer_care",
      "order_read_only",
    ],
    permissionId: [
      permissionIdList.MANAGE_ORDERS.ORDERS.LIST,
      permissionIdList.MANAGE_ORDERS.OTHER_ORDERS.LIST,
      permissionIdList.MANAGE_ORDERS.PENDING_MULTIPLE_ORDERS_REQUESTS,
      permissionIdList.MANAGE_ORDERS.UNASSIGNED_ORDERS,
    ],
  },
  {
    id: "order",
    title: "Orders",
    type: "collapse",
    icon: <Icon.Package size={20} />,
    permissions: [
      "super",
      "admin",
      "area_manager",
      "customer_care",
      "order_read_only",
    ],
    permissionId: [
      permissionIdList.MANAGE_ORDERS.ORDERS.LIST,
      permissionIdList.MANAGE_ORDERS.OTHER_ORDERS.LIST,
    ],
    children: [
      {
        id: "kitchenSet",
        title: "Kitchen Set",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "super",
          "admin",
          "area_manager",
          "customer_care",
          "order_read_only",
        ],
        navLink: "/manage_orders/orders?page=1&size=10",
        activeLink: "/manage_orders/orders",
        permissionId: [permissionIdList.MANAGE_ORDERS.ORDERS.LIST],
      },
      {
        id: "otherOrders",
        title: "Other Orders",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "super",
          "admin",
          "area_manager",
          "customer_care",
          "order_read_only",
        ],
        navLink: "/manage_other_orders/otherorders?page=1&size=10",
        activeLink: "/manage_other_orders/otherorders",
        permissionId: [permissionIdList.MANAGE_ORDERS.OTHER_ORDERS.LIST],
      },
    ],
  },
  {
    id: "pendingorders",
    title: "Pending Orders",
    type: "collapse",
    icon: <Icon.Package size={20} />,
    permissions: ["super", "admin", "area_manager", "customer_care"],
    permissionId: [
      permissionIdList.MANAGE_ORDERS.PENDING_MULTIPLE_ORDERS_REQUESTS,
      permissionIdList.MANAGE_ORDERS.UNASSIGNED_ORDERS,
    ],
    children: [
      {
        id: "pendingmultipleordersrequests",
        title: "Pending Multiple Orders Requests",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "admin", "area_manager", "customer_care"],
        navLink:
          "/manage_orders/pending_multiple_orders_requests?page=1&size=10",
        activeLink: "/manage_orders/pending_multiple_orders_requests",
        permissionId: [
          permissionIdList.MANAGE_ORDERS.PENDING_MULTIPLE_ORDERS_REQUESTS,
        ],
      },
      {
        id: "pendingunassignedordersrequests",
        title: "Unassigned Orders Requests",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "admin", "area_manager", "customer_care"],
        navLink: "/manage_orders/unassigned_orders?page=1&size=10",
        activeLink: "/manage_orders/unassigned_orders",
        permissionId: [permissionIdList.MANAGE_ORDERS.UNASSIGNED_ORDERS],
      },
    ],
  },
  {
    type: "groupHeader",
    groupTitle: "Manage Customers",
    permissions: [
      "super",
      "admin",
      "area_manager",
      "customer_care",
      "order_read_only",
    ],
    permissionId: [
      permissionIdList.MANAGE_ORDERS.CUSTOMERS.LIST,
      permissionIdList.MANAGE_ORDERS.MANAGE_SERVICE_TICKETS.LIST,
    ],
  },
  {
    id: "customer",
    title: "Customers",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: [
      "super",
      "admin",
      "area_manager",
      "customer_care",
      "designer",
    ],
    navLink: "/manage_orders/customers?page=1&size=10",
    activeLink: "/manage_orders/customers",
    permissionId: [permissionIdList.MANAGE_ORDERS.CUSTOMERS.LIST],
  },
  {
    id: "service_tikets",
    title: "Service Tickets",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["super", "admin", "area_manager"],
    navLink: "/manage_orders/manage_service_tickets?page=1&size=10",
    activeLink: "/manage_orders/manage_service_tickets",
    permissionId: [permissionIdList.MANAGE_ORDERS.MANAGE_SERVICE_TICKETS.LIST],
  },
  {
    type: "groupHeader",
    groupTitle: "Manage Products",
    permissions: ["super", "cms_person"],
    userIds: [accountIds.azhar],
    permissionId: [
      permissionIdList.MANAGE_PRODUCTS.KITCHEN.CATEGORIES.LIST,
      permissionIdList.MANAGE_PRODUCTS.KITCHEN.SHAPES.LIST,
      permissionIdList.MANAGE_PRODUCTS.KITCHEN.MODELS.LIST,
      permissionIdList.MANAGE_PRODUCTS.KITCHEN.DIMENSIONS.LIST,
      permissionIdList.MANAGE_PRODUCTS.KITCHEN.KITCHEN.LIST,

      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.CATEGORY.LIST,
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.GROUP.LIST,
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.ITEM_TYPE.LIST,
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.MANUFACTURER.LIST,
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.SUB_GROUP.LIST,
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.PRODUCTS.LIST,
    ],
  },
  {
    id: "kitchen",
    title: "Kitchen",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["super", "editor", "cms_person"],
    permissionId: [
      permissionIdList.MANAGE_PRODUCTS.KITCHEN.CATEGORIES.LIST,
      permissionIdList.MANAGE_PRODUCTS.KITCHEN.SHAPES.LIST,
      permissionIdList.MANAGE_PRODUCTS.KITCHEN.MODELS.LIST,
      permissionIdList.MANAGE_PRODUCTS.KITCHEN.DIMENSIONS.LIST,
      permissionIdList.MANAGE_PRODUCTS.KITCHEN.KITCHEN.LIST,
    ],
    children: [
      {
        id: "kCategories",
        title: "Categories",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "cms_person"],
        navLink: "/manage_products/kitchen/categories",
        activeLink: "/manage_products/kitchen/categories",
        permissionId: [
          permissionIdList.MANAGE_PRODUCTS.KITCHEN.CATEGORIES.LIST,
        ],
      },
      {
        id: "kitchenShape",
        title: "Shapes",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "cms_person"],
        navLink: "/manage_products/kitchen/shapes",
        activeLink: "/manage_products/kitchen/shapes",
        permissionId: [permissionIdList.MANAGE_PRODUCTS.KITCHEN.SHAPES.LIST],
      },
      {
        id: "kitchenModel",
        title: "Models",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "cms_person"],
        navLink: "/manage_products/kitchen/models",
        activeLink: "/manage_products/kitchen/models",
        permissionId: [permissionIdList.MANAGE_PRODUCTS.KITCHEN.MODELS.LIST],
      },
      {
        id: "kitchenDimension",
        title: "Dimensions",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "cms_person"],
        navLink: "/manage_products/kitchen/dimensions",
        activeLink: "/manage_products/kitchen/dimensions",
        permissionId: [
          permissionIdList.MANAGE_PRODUCTS.KITCHEN.DIMENSIONS.LIST,
        ],
      },
      {
        id: "kitchenProd",
        title: "Kitchen",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "cms_person"],
        navLink: "/manage_products/kitchen",
        activeLink: "/manage_products/kitchen",
        permissionId: [permissionIdList.MANAGE_PRODUCTS.KITCHEN.KITCHEN.LIST],
      },
    ],
  },
  //  {
  //   id: "products",
  //   title: "Products",
  //   type: "collapse",
  //   icon: <Icon.List size={20} />,
  //   permissions: ["super", "editor"],
  //   children: []
  //  },
  {
    id: "products",
    title: "Products",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["super", "editor"],
    userIds: [accountIds.azhar],
    permissionId: [
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.CATEGORY.LIST,
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.MANUFACTURER.LIST,
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.GROUP.LIST,
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.SUB_GROUP.LIST,
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.ITEM_TYPE.LIST,
      permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.PRODUCTS.LIST,
    ],
    children: [
      {
        id: "kCategories",
        title: "Categories",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor"],
        navLink: "/manage_products/appliances/Acategories",
        activeLink: "/manage_products/appliances/Acategories",
        permissionId: [
          permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.CATEGORY.LIST,
        ],
      },
      {
        id: "Manufacturer",
        title: "Manufacturer",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_products/appliances/manufacturer",
        activeLink: "/manage_products/appliances/Manufacturer",
        permissionId: [
          permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.MANUFACTURER
            .LIST,
        ],
      },
      {
        id: "kGroup",
        title: "Group",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_products/appliances/group",
        activeLink: "/manage_products/appliances/group",
        permissionId: [
          permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.GROUP.LIST,
        ],
      },
      {
        id: "kSubGroup",
        title: "Sub-Group",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_products/appliances/sub-group",
        activeLink: "/manage_products/appliances/sub-group",
        permissionId: [
          permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.SUB_GROUP.LIST,
        ],
      },
      {
        id: "kItemType",
        title: "Item-Type",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_products/appliances/item-type",
        activeLink: "/manage_products/appliances/item-type",
        permissionId: [
          permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.ITEM_TYPE.LIST,
        ],
      },
      {
        id: "kProducts",
        title: "Products",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        userIds: [accountIds.azhar],
        navLink: "/manage_products/appliances/products",
        activeLink: "/manage_products/appliances/products",
        permissionId: [
          permissionIdList.MANAGE_PRODUCTS.PRODUCTS.APPLIANCES.PRODUCTS.LIST,
        ],
      },
    ],
  },
  {
    type: "groupHeader",
    groupTitle: "Contents",
    permissions: ["super", "cms_person"],
    permissionId: [
      permissionIdList.MANAGE_HOMEPAGE.FIND_OUT_WHAT_YOU_LIKE,
      permissionIdList.MANAGE_HOMEPAGE.HOW_NIULI_IS_BETTER,
      permissionIdList.MANAGE_HOMEPAGE.HOW_NIULI_WORKS,
      permissionIdList.MANAGE_HOMEPAGE.IDEA_BEHIND_NIULI,
      permissionIdList.MANAGE_HOMEPAGE.WHY_NIULI,

      permissionIdList.MANAGE_PROMOTION.BANNER.LIST,
      permissionIdList.MANAGE_PROMOTION.COUPONS.LIST,

      permissionIdList.MANAGE_KEY_CONFIG.KEYS,
    ],
  },
  {
    id: "homePage",
    title: "HomePage",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["super", "editor", "cms_person"],
    permissionId: [
      permissionIdList.MANAGE_HOMEPAGE.IDEA_BEHIND_NIULI,
      permissionIdList.MANAGE_HOMEPAGE.WHY_NIULI,
      permissionIdList.MANAGE_HOMEPAGE.HOW_NIULI_WORKS,
      permissionIdList.MANAGE_HOMEPAGE.HOW_NIULI_IS_BETTER,
      permissionIdList.MANAGE_HOMEPAGE.FIND_OUT_WHAT_YOU_LIKE,
    ],
    children: [
      {
        id: "ideaBehindNiuli",
        title: "Idea Behind Niuli",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor", "cms_person"],
        navLink: "/manage_homepage/idea-behind-niuli",
        activeLink: "/manage_homepage/idea-behind-niuli",
        permissionId: [permissionIdList.MANAGE_HOMEPAGE.IDEA_BEHIND_NIULI],
      },
      {
        id: "whyNiuli",
        title: "Why Niuli",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor", "cms_person"],
        navLink: "/manage_homepage/why-niuli",
        activeLink: "/manage_homepage/why-niuli",
        permissionId: [permissionIdList.MANAGE_HOMEPAGE.WHY_NIULI],
      },
      {
        id: "howNiuliWorks",
        title: "How Niuli Works",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor", "cms_person"],
        navLink: "/manage_homepage/how-niuli-works",
        activeLink: "/manage_homepage/how-niuli-works",
        permissionId: [permissionIdList.MANAGE_HOMEPAGE.HOW_NIULI_WORKS],
      },
      {
        id: "howNiuliIsBetter",
        title: "How Niuli Is Better",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor", "cms_person"],
        navLink: "/manage_homepage/how-niuli-is-better",
        activeLink: "/manage_homepage/how-niuli-is-better",
        permissionId: [permissionIdList.MANAGE_HOMEPAGE.HOW_NIULI_IS_BETTER],
      },
      {
        id: "findOutWhatYouLike",
        title: "Find Out What You Like",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor", "cms_person"],
        navLink: "/manage_homepage/find-out-what-you-like",
        activeLink: "/manage_homepage/find-out-what-you-like",
        permissionId: [permissionIdList.MANAGE_HOMEPAGE.FIND_OUT_WHAT_YOU_LIKE],
      },
    ],
  },
  {
    id: "promotion",
    title: "Promotions",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["super", "editor", "cms_person"],
    permissionId: [
      permissionIdList.MANAGE_PROMOTION.BANNER.LIST,
      permissionIdList.MANAGE_PROMOTION.COUPONS.LIST,
    ],
    children: [
      {
        id: "Banner",
        title: "Banner",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "editor", "cms_person"],
        navLink: "/manage_promotion/banner",
        activeLink: "/manage_promotion/banner",
        permissionId: [permissionIdList.MANAGE_PROMOTION.BANNER.LIST],
      },
      {
        id: "coupons",
        title: "Coupons",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "cms_person"],
        navLink: "/manage_promotion/coupons",
        activeLink: "/manage_promotion/coupons",
        permissionId: [permissionIdList.MANAGE_PROMOTION.COUPONS.LIST],
      },
    ],
  },
  {
    id: "key_config",
    title: "Key Configurations",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super", "editor"],
    navLink: "/manage/keys",
    activeLink: "/manage/keys",
    permissionId: [permissionIdList.MANAGE_KEY_CONFIG.KEYS],
  },
  //reports section
  {
    type: "groupHeader",
    groupTitle: "Reports",
    permissions: ["super", "admin", "admin_read_only", "area_manager"],
    permissionId: [
      permissionIdList.MANAGE_REPORTS.SALES,
      permissionIdList.MANAGE_REPORTS.ACTIVITY,
      permissionIdList.MANAGE_REPORTS.SALES_REPORT,
      permissionIdList.MANAGE_REPORTS.FOLLOW_UP_REPORT,
      permissionIdList.MANAGE_REPORTS.SALES_ORDER_REPORT,
      permissionIdList.MANAGE_REPORTS.APPLIANCES_ORDERS_REPORT,
      permissionIdList.MANAGE_REPORTS.REMINDER_REPORT,
      permissionIdList.MANAGE_REPORTS.SALES_REPORT_MTD_YTD,
      permissionIdList.MANAGE_REPORTS.SALES_REPORT_SUMMARY,
      permissionIdList.MANAGE_REPORTS.KITCHEN_STOCK_AGING,
      permissionIdList.MANAGE_REPORTS.SUMMARY_REPORT,
    ],
  },
  {
    id: "sale",
    title: "Sales",
    type: "collapse",
    icon: <Icon.List size={20} />,
    permissions: ["super", "admin"],
    permissionId: [permissionIdList.MANAGE_REPORTS.SALES],
    children: [
      {
        id: "designers",
        title: "Designers",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "admin"],
        navLink: "/manage_reports/sales?page=1&size=10",
        activeLink: "/manage_reports/sales",
        permissionId: [permissionIdList.MANAGE_REPORTS.SALES],
      },
    ],
  },
  {
    id: "activities",
    title: "Activities",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super", "admin"],
    navLink: "/manage_reports/activity",
    activeLink: "/manage_reports/activity",
    permissionId: [permissionIdList.MANAGE_REPORTS.ACTIVITY],
  },
  {
    id: "emailreport",
    title: "Email Reports",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super", "admin", "admin_read_only"],
    navLink: "/email-reports",
    activeLink: "/email-reports",
    permissionId: [permissionIdList.MANAGE_REPORTS.EMAIL_REPORTS],
  },
  {
    id: "salesreport",
    title: "Sales Reports",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super", "admin", "area_manager", "admin_read_only"],
    navLink: "/sales-reports?page=1&type=appRegistration",
    activeLink: "/sales-reports",
    permissionId: [permissionIdList.MANAGE_REPORTS.SALES_REPORT],
  },
  {
    id: "followUpreport",
    title: "Follow-Up Reports",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super", "admin", "admin_read_only"],
    navLink: "/manage_reports/followUp",
    activeLink: "/manage_reports/followUp",
    permissionId: [permissionIdList.MANAGE_REPORTS.FOLLOW_UP_REPORT],
  },
  {
    id: "salesOrderReport",
    title: "Sales Order Reports",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super", "admin", "area_manager", "admin_read_only"],
    navLink: "/manage_reports/salesOrder?page=1&size=10",
    activeLink: "/manage_reports/salesOrder",
    permissionId: [permissionIdList.MANAGE_REPORTS.SALES_ORDER_REPORT],
  },
  {
    id: "appliancesOrders",
    title: "Appliances Orders",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super", "admin", "area_manager"],
    navLink: "/manage_reports/appliances-orders",
    activeLink: "/manage_reports/Appliances-orders",
    permissionId: [permissionIdList.MANAGE_REPORTS.APPLIANCES_ORDERS_REPORT],
  },
  {
    id: "ReminderReport",
    title: "Reminder Reports",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super", "admin", "area_manager", "admin_read_only"],
    navLink: "/manage_reports/reminderReport?page=1&size=10",
    activeLink: "/manage_reports/reminderReport",
    permissionId: [permissionIdList.MANAGE_REPORTS.REMINDER_REPORT],
  },
  {
    id: "sales-report-mtd-ytd",
    title: "Sales Report MTD-YTD",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super"],
    navLink: "/manage_reports/sales-report-mtd-ytd?page=1&size=10",
    activeLink: "/manage_reports/sales-report-mtd-ytd",
    permissionId: [permissionIdList.MANAGE_REPORTS.SALES_REPORT_MTD_YTD],
  },
  {
    id: "sales-report-summary",
    title: "Sales Report Summary",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super"],
    userIds: [accountIds.onder],
    navLink: "/manage_reports/sales-report-summary?page=1&size=10",
    activeLink: "/manage_reports/sales-report-summary",
    permissionId: [permissionIdList.MANAGE_REPORTS.SALES_REPORT_SUMMARY],
  },
  {
    id: "kitchen-stock-aging",
    title: "Kitchen Stock Aging",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super"],
    navLink: "/manage_reports/kitchen-stock-aging?page=1&size=10",
    activeLink: "/manage_reports/kitchen-stock-aging",
    permissionId: [permissionIdList.MANAGE_REPORTS.KITCHEN_STOCK_AGING],
  },
  {
    id: "summary-report",
    title: "Summary Report",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["super"],
    userIds: [accountIds.onder],
    navLink: "/manage_reports/summary-report",
    activeLink: "/manage_reports/summary-report",
    permissionId: [permissionIdList.MANAGE_REPORTS.SUMMARY_REPORT],
  },
  {
    type: "groupHeader",
    groupTitle: "Manage Sales Target",
    permissions: ["super"],
    permissionId: [permissionIdList.MANAGE_SALES_TARGET.LIST],
  },
  {
    id: "salesTarget",
    title: "Sales Target",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super"],
    navLink: "/manage_target/sales?page=1&size=10",
    activeLink: "/manage_target/sales",
    permissionId: [permissionIdList.MANAGE_SALES_TARGET.LIST],
  },

  //reminders section
  {
    type: "groupHeader",
    groupTitle: "Manage Reminders",
    permissions: ["super", "admin", "area_manager"],
    permissionId: [
      permissionIdList.MANAGE_REMINDER.AUTOMATED_REMINDER.LIST,
      permissionIdList.MANAGE_REMINDER.MANUAL_REMINDER.LIST,
      permissionIdList.MANAGE_REMINDER.PENDING_REMINDER.LIST,
    ],
  },
  {
    id: "reminders",
    title: "Reminders",
    type: "collapse",
    icon: <img src={reminder} size={20} style={{ paddingRight: "10px" }} />,
    permissions: ["super", "admin", "area_manager"],
    permissionId: [
      permissionIdList.MANAGE_REMINDER.AUTOMATED_REMINDER.LIST,
      permissionIdList.MANAGE_REMINDER.MANUAL_REMINDER.LIST,
      permissionIdList.MANAGE_REMINDER.PENDING_REMINDER.LIST,
    ],
    children: [
      {
        id: "manualreminders",
        title: "Manual Reminder",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "admin", "area_manager"],
        navLink: "/manage_reminder/reminder?page=1&size=10",
        activeLink: "/manage_reminder/reminder",
        permissionId: [permissionIdList.MANAGE_REMINDER.MANUAL_REMINDER.LIST],
      },
      {
        id: "automatedreminders",
        title: "Automated Reminder",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        navLink: "/manage_reminder/automated?page=1&size=10",
        activeLink: "/manage_reminder/automated",
        permissionId: [permissionIdList.MANAGE_REMINDER.AUTOMATED_REMINDER.LIST],
      },
      {
        id: "pendingreminders",
        title: "Pending Reminder",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "admin", "area_manager"],
        navLink: "/manage_reminder/pending?page=1&size=10",
        activeLink: "/manage_reminder/pending",
        permissionId: [permissionIdList.MANAGE_REMINDER.PENDING_REMINDER.LIST],
      },
    ],
  },
  {
    type: "groupHeader",
    groupTitle: "Manage Campaigns",
    permissions: ["super"],
    permissionId: [
      permissionIdList.MANAGE_CAMPAIGN.MANAGE_LEADS.LIST,
      permissionIdList.MANAGE_CAMPAIGN.SOCIAL_DASHBOARD,
      permissionIdList.MANAGE_CAMPAIGN.SOCIAL_MEDIA_CAMPAIGN.LIST,
    ],
  },
  {
    id: "Marketingcampaign",
    title: "Social Media Marketing",
    permissions: ["super", "area_manager"],
    userIds: [accountIds.asim, accountIds.onder, accountIds.onderQA],
    type: "collapse",
    icon: <Icon.Monitor size={20} />,
    permissionId: [
      permissionIdList.MANAGE_CAMPAIGN.MANAGE_LEADS.LIST,
      permissionIdList.MANAGE_CAMPAIGN.SOCIAL_DASHBOARD,
      permissionIdList.MANAGE_CAMPAIGN.SOCIAL_MEDIA_CAMPAIGN.LIST,
    ],
    children: [
      {
        id: "socialMediaDashboard",
        title: "Dashboard",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        userIds: [accountIds.asim, accountIds.onder, accountIds.onderQA],
        navLink: "/leads/social-dashboard",
        activeLink: "/leads/social-dashboard",
        permissionId: [permissionIdList.MANAGE_CAMPAIGN.SOCIAL_DASHBOARD],
      },
      {
        id: "campaign",
        title: "Social Media Campaign",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super"],
        userIds: [accountIds.asim, accountIds.onder, accountIds.onderQA],
        navLink: "/leads/social/?page=1&size=10&platform=tiktok",
        activeLink: "/leads/social",
        permissionId: [permissionIdList.MANAGE_CAMPAIGN.SOCIAL_MEDIA_CAMPAIGN.LIST],
      },
      {
        id: "socialMediaReport",
        title: "Leads Management",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["super", "area_manager"],
        userIds: [accountIds.asim, accountIds.onder, accountIds.onderQA],
        navLink: "/leads?page=1&size=10",
        activeLink: "/leads",
        permissionId: [
          permissionIdList.MANAGE_CAMPAIGN.MANAGE_LEADS.LIST,
        ],
      },
    ],
  },
  {
    type: "groupHeader",
    groupTitle: "Manage SAP",
    permissions: [isProd ? "" : "super"],
    userIds: [accountIds.dawood, accountIds.codewave, accountIds.shehzad],
    permissionId: [
      permissionIdList.MANAGE_SAP.SAP_SYNC_ISSUES.LIST,
      permissionIdList.MANAGE_SAP.POSTING_PERIOD.LIST,
    ],
  },
  {
    id: "sapSyncIssues",
    title: "SAP sync issues",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: [isProd ? "" : "super"],
    userIds: [
      accountIds.dawood,
      accountIds.codewave,
      accountIds.shehzad,
      accountIds.zukwan,
    ],
    navLink: "/sapSync/list?page=1&size=10",
    activeLink: "/sapSync/list",
    permissionId: [permissionIdList.MANAGE_SAP.SAP_SYNC_ISSUES.LIST],
  },
  {
    id: "postingPeriod",
    title: "Posting Period",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: ["super"],
    userIds: [""],
    navLink: "/sapSync/posting-period?page=1&size=10",
    activeLink: "/sapSync/posting-period",
    permissionId: [permissionIdList.MANAGE_SAP.POSTING_PERIOD.LIST],
  },
  // Authorization
  {
    type: "groupHeader",
    groupTitle: "Authorization",
    permissions: [isProd ? "" : "super"],
    userIds: [accountIds.dawood, accountIds.codewave],
    permissionId: [
      permissionIdList.MANAGE_AUTHORIZATION.USER_TYPE.LIST,
      permissionIdList.MANAGE_AUTHORIZATION.PERMISSIONS.UPDATE_PERMISSION,
    ],
  },
  {
    id: "userGroup",
    title: "User Type",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: [isProd ? "" : "super"],
    userIds: [accountIds.dawood, accountIds.codewave],
    navLink: "/authorization/userGroup/list?page=1&size=10",
    activeLink: "/authorization/userGroup/list",
    permissionId: [permissionIdList.MANAGE_AUTHORIZATION.USER_TYPE.LIST],
  },
  {
    id: "permissions",
    title: "Permissions",
    type: "item",
    icon: <Icon.List size={24} />,
    permissions: [isProd ? "" : "super"],
    userIds: [accountIds.dawood, accountIds.codewave],
    navLink: "/authorization/permissions",
    activeLink: "/authorization/permissions",
    permissionId: [permissionIdList.MANAGE_AUTHORIZATION.PERMISSIONS.LIST],
  },
];

export default navigationConfig;
